<template>
  <div id="app">
    <v-app class="transparent_bg">
      <div class="list-table">
        <v-container grid-list-xl>
          <v-snackbar v-model="showSnackbar" :timeout="5000">
            {{ snackbarText }}
            <v-btn color="#673ab7" text @click="showSnackbar = false">
              Fechar
            </v-btn>
          </v-snackbar>

          <v-layout row wrap>
            <v-flex lg12>
              <v-card>
                <div class="layout column align-center">
                  <h3>{{ currentProject.name }}</h3>
                  <h3>{{ currentProject.ambient }}</h3>
                </div>

                <v-divider></v-divider>

                <div class="layout column align-center">
                  <PackVolumeBar
                    v-if="!this.isAllPacked"
                    :loading="loading"
                    :external-pieces-to-pack="piecesToPack"
                    :pieces="pieces"
                    :sync-pieces-to-pack="_syncPiecesToPack"
                  />
                  <v-btn
                    v-else
                    color="primary"
                    small
                    :loading="loading"
                    class="mt-3 mb-3"
                    @click="_toVolumes"
                  >
                    Conferir Pacotes
                  </v-btn>
                </div>

                <v-divider></v-divider>

                <v-toolbar card color="white">
                  <v-text-field
                    flat
                    solo
                    v-model="search"
                    prepend-icon="search"
                    placeholder="Filtrar"
                    hide-details
                    class="hidden-sm-and-down"
                  ></v-text-field>
                </v-toolbar>
                <v-divider></v-divider>
                <v-card-text class="pa-0">
                  <v-data-table
                    :headers="complex.headers"
                    :items="complex.items"
                    pagination.sync="query"
                    :rows-per-page-items="[1000, 2000, 5000]"
                    class="elevation-0"
                    item-key="_id"
                  >
                    <template
                      slot="items"
                      slot-scope="props"
                      class="justify-center"
                    >
                      <td>{{ props.item.name }}</td>
                      <td>{{ props.item.width }} x {{ props.item.height }}</td>
                      <td>{{ props.item.pieceId }}</td>
                      <td>
                        <Tag :status="props.item.status" />
                      </td>
                      <td>
                        <v-tooltip bottom v-if="_isReadyToPack(props.item)">
                          <template v-slot:activator="{ on }">
                            <v-btn
                              icon
                              dark
                              color="red"
                              medium
                              class="right"
                              v-on="on"
                              @click="
                                _addOrRemovePieceToPack(props.item.pieceId)
                              "
                            >
                              <v-icon>{{
                                _isPieceAdded(props.item.pieceId)
                                  ? 'delete'
                                  : 'add'
                              }}</v-icon>
                            </v-btn>
                          </template>
                          <span>{{
                            _isPieceAdded(props.item.pieceId)
                              ? 'Remover do pacote'
                              : 'Adicionar ao pacote'
                          }}</span>
                        </v-tooltip>

                        <v-tooltip bottom v-if="_isPiecePacked(props.item)">
                          <template v-slot:activator="{ on }">
                            <v-btn
                              icon
                              dark
                              color="red"
                              medium
                              class="right"
                              v-on="on"
                              @click="_printTag(props.item.volume)"
                            >
                              <v-icon>print</v-icon>
                            </v-btn>
                          </template>
                          <span>Imprimir etiqueta</span>
                        </v-tooltip>
                      </td>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </div>
    </v-app>
    <printable-tag-dialog
      v-if="currentVolume"
      :show="showPrintDialog"
      :volume="currentVolume"
      :dismiss-action="() => (this.showPrintDialog = false)"
    ></printable-tag-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import constants from '@/utils/constants';
import Tag from '@/components/project/Tag';
import PackVolumeBar from '@/components/project/PackVolumeBar';
import PrintableTagDialog from '@/components/dialogs/PrintableTagDialog';

export default {
  components: { Tag, PackVolumeBar, PrintableTagDialog },
  data() {
    return {
      loading: false,
      showSnackbar: false,
      showPrintDialog: false,
      snackbarText: '',
      currentUser: null,
      currentVolume: null,
      piecesToPack: [],
      projects: [],
      currentProject: [],
      query: {
        descending: false,
        page: 1,
        rowsPerPage: 10,
        sortBy: 'name',
        totalItems: 0
      },
      search: '',
      complex: {
        headers: [
          {
            text: 'Nome',
            value: 'name'
          },
          {
            text: 'Tamanho',
            value: 'dimensions'
          },
          {
            text: 'ID',
            value: 'pieceId'
          },
          {
            text: 'Status',
            value: 'status'
          },
          {
            text: '',
            value: '',
            sortable: false
          }
        ],
        items: []
      }
    };
  },
  computed: {
    ...mapGetters(['getPieces', 'getProjects', 'getUser']),
    ...mapState({
      pieces: (state) => state.piece.pieces
    }),
    isAllPacked() {
      return (
        this.pieces.filter(
          (piece) =>
            piece.status !== constants.STATUS_PACKED &&
            piece.project === this.$route.params.id
        ).length === 0
      );
    }
  },

  created() {
    this.currentUser = this.getUser;
    this.projects = this.getProjects;
    this.currentProject = this._getCurrentProject();
  },

  async mounted() {
    this.currentUser = this.getUser;
    try {
      await this._listPieces(this.$route.params.id);
    } catch (e) {
      console.log(e);
    }
    this.complex.items = this.pieces;
  },
  methods: {
    ...mapActions({
      _listPieces: 'listPieces',
      _getVolume: 'getVolume'
    }),

    _searchProjects(query) {
      console.log(query);
    },

    _getCurrentProject() {
      const filtered = this.projects.filter(
        (project) => project._id === this.$route.params.id
      );
      return filtered[0] ? filtered[0] : {};
    },
    _addOrRemovePieceToPack(pieceToPack) {
      if (this.piecesToPack.includes(pieceToPack)) {
        this.piecesToPack.splice(this.piecesToPack.indexOf(pieceToPack), 1);
      } else {
        this.piecesToPack.push(pieceToPack);
      }
    },
    _syncPiecesToPack(piecesToPack) {
      this.piecesToPack = piecesToPack;
    },
    _isPieceAdded(piece) {
      return this.piecesToPack.includes(piece);
    },
    _isPiecePacked(piece) {
      return piece.status === constants.STATUS_PACKED;
    },
    _isReadyToPack(piece) {
      return piece.status === constants.STATUS_READY_FOR_PACKAGE;
    },
    _toVolumes() {
      this.$router.push(`/project/${this.$route.params.id}/volumes`);
    },
    async _printTag(volumeId) {
      const volume = await this._getVolume(volumeId);
      console.log(volume);
      this.currentVolume = volume;
      this.showPrintDialog = true;
    }
  },
  watch: {
    pieces(newValue) {
      this.complex.items = newValue;
    }
  }
};
</script>

<style lang="css" scoped></style>
